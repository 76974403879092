import isDev from "./EnvironmentHelper";

export default function baseUrl(): string
{
    let url = 'https://api.norik.no/v1/';
    if (isDev())
    {
        url = 'https://localhost:44353/v1/';
    }
    return url;
}