import { useEffect } from "react";
import { Box, Grid } from "@mui/material";
import { ReactComponent as Logo } from "./../../norik-logo.svg";

function Header(props) {
  useEffect(() => {
    document.title = "Norik Go - " + props.clientObjectName;
  });
  return (
    <header>
      <Box
        display="flex"
        justifyContent="center"
        width={"100%"}
        className="slanted"
      >
        <Grid container maxWidth="1140px" px={3}>
          <Grid item xs={12}>
            <Logo fill="white" width="115" height="60" />
          </Grid>
          <Grid item xs={12}>
            <h2>Registrer nytt avvik</h2>
            <p className="lead">{props.clientObjectName}</p>
          </Grid>
        </Grid>
      </Box>
    </header>
  );
}

export default Header;
