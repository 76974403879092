import "./SuccessfulActionPost.css";
import Button from "@mui/material/Button";

function SuccessfulActionPost(props) {
  const refreshPage = function refreshPage(){ 
    window.location.reload(); 
  };

  return (
    <>
    <div className="container">
      <div className="box">
        <h1>Avvik opprettet!</h1>
        <p>Avviket er nå lagret og sendt til ansvarlig for behandling. Din referanse er avvik id: {props.serverActionId}.</p>
        <Button variant="contained" onClick={refreshPage}>
          Registrer nytt avvik
        </Button>
      </div>
    </div>
    </>
  );
}

export default SuccessfulActionPost;