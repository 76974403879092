import { Box, Grid } from "@mui/material";

const currentYear = new Date().getFullYear();

function Footer() {
  return (
    <footer>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        width={"100%"}
      >
        <Grid
          container
          spacing={3}
          px={3}
          py={1}
          alignItems="center"
          justifyContent="center"
          maxWidth="1140px"
        >
          <Grid item xs={12} my={12}>
            <hr />
            {currentYear} @ Norik AS
          </Grid>
        </Grid>
      </Box>
    </footer>
  );
}

export default Footer;
