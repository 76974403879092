import { CircularProgress } from "@mui/material";

function SendingProgress(){ 
    const indicatorSize=180;

    return (<CircularProgress size={indicatorSize}
        sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: `${-indicatorSize/2}px`,
        marginLeft: `${-indicatorSize/2}px`
        }} />);
}

export default SendingProgress;