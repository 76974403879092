import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { nbNO } from "@mui/material/locale";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import getClientObject from "api/fetchClientObject";
import ErrorPage from "components/errorPage/ErrorPage";
import InvalidId from "components/invalidId/InvalidId";
import LoadingProgress from "components/loadingProgress/LoadingProgress";
import nbLocale from "date-fns/locale/nb";
import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import "./App.css";
import ActionForm from "./components/actionForm/ActionForm";
import Footer from "./components/footer/Footer";
import Header from "./components/header/Header";

function App() {
  const theme = createTheme(
    {
      palette: {
        primary: { main: "#1976d2" },
      },
    },
    nbNO
  );

  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const idIsInvalid = id === null || id === undefined;

  const { data, isLoading, isError } = useQuery(
    "clientObject",
    () => getClientObject(id),
    { enabled: !idIsInvalid }
  );

  if (isError) {
    return <ErrorPage />;
  }

  if (idIsInvalid) {
    return <InvalidId />;
  }

  if (isLoading) {
    return <LoadingProgress />;
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={nbLocale}>
      <ThemeProvider theme={theme}>
        <Header clientObjectName={data.clientObjectName} />
        <ActionForm Id={id} Data={data} />
        <Footer />
      </ThemeProvider>
    </LocalizationProvider>
  );
}

export default App;
