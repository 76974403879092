import "./ErrorPage.css";
import { ReactComponent as Logo } from "./../../norik-logo.svg";
import Button from "@mui/material/Button";

function ErrorPage() {
    const refreshPage = function refreshPage(){ 
        window.location.reload(); 
      };

  return (
    <>
    <div className="container">
      <div className="box">
        <Logo fill="darkblue" width="150" height="180" />
        <h1>Det oppstod en feil</h1>
        <Button variant="contained" onClick={refreshPage}>
          Prøv på nytt
        </Button>
      </div>
    </div>
    </>
  );
}

export default ErrorPage;