import "./InvalidId.css";
import { ReactComponent as Logo } from "./../../norik-logo.svg";

function InvalidId() {
  return (
    <>
    <div className="container">
      <div className="box">
        <Logo fill="darkblue" width="150" height="180" />
        <h1>Ugyldig id</h1>
        <p>For at du skal kunne registrere avvik må du koble deg til riktig objekt ved å scanne QR-koden som er hengt opp i lokalene.</p>
      </div>
    </div>
    </>
  );
}

export default InvalidId;
