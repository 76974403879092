import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { QueryClient, QueryClientProvider } from 'react-query';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import axios from 'axios';
import baseUrl from 'helpers/BaseUrlHelper';

axios.defaults.baseURL = baseUrl();
axios.defaults.headers.common = {
  'Access-Control-Allow-Origin': 'https://go.norik.no',
  'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
};
axios.defaults.withCredentials = false;

if (!('process' in window)) {
  // @ts-ignore
  window.process = {}
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const router = createBrowserRouter([
  {
    path: "/actions/add",
    element: <App />,
  },
]);

ReactDOM.render(
  <React.StrictMode>    
    <QueryClientProvider client={queryClient}>              
        <RouterProvider router={router} />        
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
