import ClockIcon from "@mui/icons-material/AccessTime";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import DateTimePicker from "@mui/lab/DateTimePicker";
import { Grid, MenuItem } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import axios from "axios";
import ErrorPage from "components/errorPage/ErrorPage";
import SendingProgress from "components/sendingProgress/SendingProgress";
import SuccessfulActionPost from "components/successfulActionPost/SuccessfulActionPost";
import React, { ChangeEvent } from "react";
import { useMutation } from "react-query";
import GoActionRequest from "types/GoActionRequest";

function ActionForm(props: any) {
  const [image, setImage] = React.useState<string>();
  const [description, setDescription] = React.useState("");
  const [category, setCategory] = React.useState("");
  const [dateTime, setDateTime] = React.useState<Date | null>(new Date());
  const [createdByName, setCreatedByName] = React.useState("");
  const [successfulPost, setSuccessfulPost] = React.useState(false);
  const [serverActionId, setServerActionId] = React.useState("");
  const [failedPost, setFailedPost] = React.useState(false);
  const [submitButtonDisabled, setSubmitButtonDisabled] = React.useState(false);
  const [sendingAction, setSendingAction] = React.useState(false);

  const handleDateTimeChange = (newValue: Date | null) => {
    setDateTime(newValue);
  };

  const handleImageChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;
    setImage(URL.createObjectURL(e.target.files[0]));
  };

  const handleDescriptionChange = (e: ChangeEvent<HTMLInputElement>) => {
    setDescription(e.currentTarget.value);
  };

  const handleCategoryChange = (e: ChangeEvent<HTMLInputElement>) => {
    setCategory(e.target.value);
  };

  const handleCreatedByNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setCreatedByName(e.currentTarget.value);
  };

  const getBlobFromUrl = async (url: string) => {
    const data = await fetch(url);
    const blob = await data.blob();
    return blob;
  };

  const createActionRequest = async (data: GoActionRequest) => {
    const { data: response } = await axios.post<number>("go/action", data, {
      headers: {
        "Content-Type": `multipart/form-data`,
      },
      timeout: 30000,
    });
    return response;
  };

  const { mutate } = useMutation(createActionRequest, {
    onSuccess: (data) => {
      setServerActionId(data.toString());
      setFailedPost(false);
      setSuccessfulPost(true);
      window.scroll(0, 0);
    },
    onError: () => {
      setSuccessfulPost(false);
      setFailedPost(true);
      window.scroll(0, 0);
    },
    onSettled: () => {
      setSubmitButtonDisabled(false);
      setSendingAction(false);
    },
  });

  const submitForm = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    setSubmitButtonDisabled(true);
    setSendingAction(true);

    try {
      const data: GoActionRequest = {
        GoId: props.Id,
        ActionText: description,
        ActionCategory: category,
        ActionDate: dateTime,
        CreatedByName: createdByName,
        Image: null,
      };

      if (image) {
        data.Image = await getBlobFromUrl(image);
      }
      console.log(data);

      mutate(data);
    } catch (err) {
      console.log(err);
      setSubmitButtonDisabled(false);
      setSendingAction(false);
    }
  };

  if (failedPost) {
    return <ErrorPage />;
  }

  if (successfulPost) {
    return <SuccessfulActionPost serverActionId={serverActionId} />;
  }

  if (sendingAction) {
    return <SendingProgress />;
  }

  return (
    <form onSubmit={submitForm}>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        width={"100%"}
      >
        <Grid
          container
          spacing={3}
          padding={3}
          alignItems="center"
          justifyContent="center"
          maxWidth="1140px"
        >
          <Grid item xs={12}>
            <label htmlFor="file-upload" style={{ cursor: "pointer" }}>
              <CameraAltIcon htmlColor="gray" fontSize="large" /> Ta bilde
            </label>
            <input
              id="file-upload"
              style={{ display: "none" }}
              type="file"
              accept="image/jpeg"
              capture="environment"
              onChange={handleImageChange}
            />
            <img src={image} alt="" width="100%"></img>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Beskrivelse"
              required
              multiline
              rows={3}
              fullWidth
              value={description}
              onChange={handleDescriptionChange}
            ></TextField>
          </Grid>
          {props.Data.useCategoryField && (
            <Grid item xs={12}>
              <TextField
                label="Kategori"
                select
                required={props.Data.requireCategoryField}
                fullWidth
                value={category}
                onChange={handleCategoryChange}
              >
                {props.Data.categories &&
                  props.Data.categories.map((c: string) => {
                    return <MenuItem value={c}>{c}</MenuItem>;
                  })}
              </TextField>
            </Grid>
          )}
          <Grid item xs={12}>
            <TextField
              label="Ditt navn"
              fullWidth
              onChange={handleCreatedByNameChange}
            ></TextField>
          </Grid>
          <Grid item xs={12}>
            <DateTimePicker
              label="Dato/klokke"
              inputFormat="dd.MM.yyyy HH:mm"
              ampm={false}
              value={dateTime}
              components={{
                OpenPickerIcon: ClockIcon,
              }}
              onChange={handleDateTimeChange}
              renderInput={(params) => <TextField {...params} required />}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              type="submit"
              disabled={submitButtonDisabled}
            >
              Registrer avvik
            </Button>
          </Grid>
        </Grid>
      </Box>
    </form>
  );
}

export default ActionForm;
